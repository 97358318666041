<template>
	<transition>
		<DxDataGrid
			class="grid-box"
			:allow-column-reordering="true"
			:data-source="contentData.surveyAnswerDetail"
			:show-column-headers="true"
			:show-borders="false"
			:column-auto-width="true"
			:show-row-lines="true"
			:show-column-lines="false"
			height="100%"
			width="100%"
		>
			<DxFilterRow :visible="true" />
			<DxSelection mode="single" />
			<DxColumn caption="부서정보" data-field="deptNmPath" alignment="center" />
			<DxColumn caption="상담사 [ID]" data-field="agtNm" alignment="center" :cell-template="cellTemplateByColumn" width="120" />
			<DxColumn caption="점수" data-field="score" alignment="center" />
		</DxDataGrid>
	</transition>
</template>

<script>
import { DxDataGrid, DxColumn, DxSelection, DxFilterRow, DxLookup } from 'devextreme-vue/data-grid';

export default {
	components: {
		DxDataGrid,
		DxColumn,
		DxSelection,
		DxFilterRow,
		DxLookup,
	},
	props: {
		contentData: Object,
	},
	watch: {},
	data() {
		return {};
	},
	computed: {},
	methods: {
		/** @description : 참여율, 참여인원 형식 변환 메서드 */
		cellTemplateByColumn: async (container, option) => {
			let tag = document.createElement('div');
			let value = `${option.data.agtNm} [${option.data.agtId}]`;
			tag.innerHTML = value;
			container.append(tag);
		},
	},
	created() {},
	mounted() {},
};
</script>
